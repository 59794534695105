import { useEffect, useState, useRef } from "react";
import { checkCookie } from "services/authentication";
import { EVA_LOGIN } from 'helpers/urlHelper';
import { getItemFromStore } from "helpers/utils";

const useTokenCheck = () => {
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setisAuthenticated] = useState(false);
    const isMounted = useRef(null);
    const [path, setPath] = useState('');

    const redirectToEva = () => {
        window.location.href = EVA_LOGIN;
        return;
    }

    const checkSuccess = (user) => {
        setPath('/');
        setLoading(false);
        setisAuthenticated(user.success ?? user.isAuthenticated);
    }

    useEffect(() => {
        isMounted.current = true;
        const user = getItemFromStore('user', {}, sessionStorage);
        if (user.isAuthenticated)
            return checkSuccess(user);

        const controller = new AbortController();

        checkCookie(controller.signal)
            .then(res => {
                if (!isMounted.current) return null;

                if (res && res.success)
                    return checkSuccess(res);
                else
                    return redirectToEva();
            })
            .catch(err => {
                console.log(err.message);
                return redirectToEva();
            });

        return () => {
            isMounted.current = false;
            controller.abort();
        }
    }, [])

    return [loading, isAuthenticated, path];
}

export default useTokenCheck;
