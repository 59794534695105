import React from 'react';
import PropTypes from 'prop-types';
import PartialTable from "components/partials/stock-location/PartialTable";
const columns = [
    {
        accessor: 'siteName',
        Header: 'Site Name'
    },
    {
        accessor: 'code',
        Header: 'Code'
    },
    {
        accessor: 'description',
        Header: 'Description'
    },
    {
        accessor: 'status',
        Header: 'Status'
    },
    {
        accessor: 'action',
        Header: 'Action',
    }
];

const AreaTable = ({ areas, paging, total, nextPage, previousPage, gotoPage, onPageSizeChange }) => {
    return (
        <PartialTable
            data={areas}
            columns={columns}
            paging={paging}
            total={total}
            nextPage={nextPage}
            previousPage={previousPage}
            gotoPage={gotoPage}
            onPageSizeChange={onPageSizeChange}
            view={"/stock-location/area-management/area-manager?id="}
        />
    )
}

AreaTable.propTypes = {
    areas: PropTypes.arrayOf(PropTypes.object),
    paging: PropTypes.object,
    total: PropTypes.number,
    nextPage: PropTypes.func,
    previousPage: PropTypes.func,
    gotoPage: PropTypes.func,
    onPageSizeChange: PropTypes.func
}

export default AreaTable;