import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { searchLocations } from 'services/stock-location/locationServices';
import { BaseTableRQ } from 'components/pages/stock-location/models/BaseTable';

const LocationDropdown = ({ selectedOptions, handleChange, isMulti = false }) => {
  const [locations, setLocations] = useState([]);
  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    searchLocations(new BaseTableRQ(1, 100, true, true))
      .then(res => {
        setLocations(res.items.map(x => {
          return {
            ...x, value: x.id, label: x.barcode
          }
        }));
      })
      .catch(err => {
        console.log('Error in fetch', err);
      });

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Select
      isMulti={isMulti}
      name="bins"
      options={locations}
      value={
        typeof selectedOptions === 'number'
          ? locations?.find(x => x.value === selectedOptions)
          : selectedOptions
      }
      onChange={options => handleChange(options)}
      classNamePrefix="react-select"
      placeholder="Select a Location from the list..."
    />
  );
};

LocationDropdown.propTypes = {
  selectedOptions: PropTypes.oneOfType([PropTypes.number, PropTypes.array, PropTypes.object]),
  handleChange: PropTypes.func,
  isMulti: PropTypes.bool
};

export default LocationDropdown;
