import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import ProjectFormItem from 'components/partials/projects/ProjectFormItem';
import SiteDropdown from 'components/Dropdowns/SiteDropdown';
import { toast } from 'react-toastify';
import {
  getLocationById,
  postLocation,
  patchLocation
} from 'services/stock-location/locationServices';
import BasicTemplate from 'components/Toast/BasicTemplate';
import AreaDropdown from 'components/Dropdowns/AreaDropdown';
import BaseDropdown from 'components/Dropdowns/BaseDropdown';

export default function LocationManager() {
  const [params] = useSearchParams();
  const [isWaitingSave, setIsWaitingSave] = useState(false);
  const [location, setLocation] = useState({});
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    if (params.has('id')) {
      const id = params.get('id');
      getLocationById(id).then(res => {
        console.log(res?.data);
        if (res?.data && res?.data.id > 0) {
          setLocation(handleMapLocation(res?.data))
          return;
        }
      });
      return;
    }
    return;
  };

  const handleMapLocation = data => {
    return {
      ...data,
      level1: data?.rack,
      level2: data?.shelf,
      level3: data?.location,
      status: data?.status == 'Live' ? 0 : 1
    };
  };
  const handleInputChange = (e, name) => {
    const {
      target: { value }
    } = e;
    let barcode = "";
    switch (name) {
      case 'locationX':
        barcode = genBarCode(location.site, location.areaCode, value, location.level2, location.level3);
        setLocation({
          ...location, level1: value,
          rack: value, barcode: barcode
        })
        break;
      case 'locationY':
        barcode = genBarCode(location.site, location.areaCode, location.level1, value, location.level3);
        setLocation({
          ...location, level2: value,
          shelf: value, barcode: barcode
        })
        break;
      case 'locationZ':
        barcode = genBarCode(location.site, location.areaCode, location.level1, location.level2, value);
        setLocation({
          ...location, level3: value,
          location: value, barcode: barcode
        })
        break;
      case 'notes':
        setLocation({
          ...location, notes: value
        })
        break;
      default:
        break;
    }

    return;
  };

  const saveLocationData = async () => {
    setIsWaitingSave(true);
    if (!checkRequired()) {
      setIsWaitingSave(false);
      return false;
    }

    console.log(location);
    let result = null;
    if (location.id > 0) {
      result = await patchLocation(location, location.id);
    } else {
      result = await postLocation(location);
    }

    const message =
      result.message ??
      'There was a problem saving the location. Please check your entires and try again.';
    result.success
      ? toast.success(
        <BasicTemplate
          success={result.success}
          text="Location successfully saved!"
        />
      )
      : toast.error(<BasicTemplate success={result.success} text={message} />);
    window.scrollTo(0, 0);
    setIsWaitingSave(false);
    return;
  };

  const checkRequired = () => {
    let valid = true;
    if (location.siteID?.length <= 0) {
      toast.error(<BasicTemplate text="Please enter a siteID to continue." />);
      valid = false;
    }
    if (location.locationTypeID?.length <= 0) {
      toast.error(
        <BasicTemplate text="Please select Location Type to continue." />
      );
      valid = false;
    }
    if (location.areaID?.length <= 0) {
      toast.error(<BasicTemplate text="Please select Area to continue." />);
      valid = false;
    }
    if (location.storageTypeID?.length <= 0) {
      toast.error(
        <BasicTemplate text="Please select Storage Type to continue." />
      );
      valid = false;
    }
    if (location.level1 === '') {
      toast.error(<BasicTemplate text="Please input level1 to continue." />);
      valid = false;
    }
    if (location.level2 === '') {
      toast.error(<BasicTemplate text="Please input level2 to continue." />);
      valid = false;
    }
    if (location.level3 === '') {
      toast.error(<BasicTemplate text="Please input level3 to continue." />);
      valid = false;
    }
    return valid;
  };

  const genBarCode = (site, area, x, y, z) => {
    return (site ? site : "-") + (area ? area : "-") + " " +
      (x ? x : "-") + (y ? y : "-") + (z ? z : "-");
  }
  const singleSelectChange = (option, key) => {
    let barcode = '';
    switch (key) {
      case 'siteID':
        barcode = genBarCode(option.code, location.areaCode, location.level1, location.level2, location.level3);
        setLocation({
          ...location, siteID: option.value,
          site: option.code, barcode: barcode
        })
        break;
      case 'areaID':
        barcode = genBarCode(location.site, option.code, location.level1, location.level2, location.level3);
        setLocation({
          ...location, areaID: option.value,
          areaCode: option.code, barcode: barcode
        })
        break;
      case 'status':
        setLocation({
          ...location, status: option.value
        })
        break;
      default:
        return;
    }
    return;
  };

  return (
    <Row>
      <Col xs={12}>
        <PageHeader
          title={
            location && location.id > 0
              ? 'Edit ' + location.barcode
              : 'Create Location'
          }
          description={`<p>Use this page to manage your locations. Assign actions below the description.</p><p>Leave an input blank to ignore the field.</p>`}
          className="mb-3"
        ></PageHeader>
        <Card>
          <Card.Body>
            <ProjectFormItem
              id="siteID"
              title="Site"
              inputElement={
                <SiteDropdown
                  selectedOptions={location?.siteID}
                  handleChange={opts => singleSelectChange(opts, 'siteID')}
                  isMulti={false}
                />
              }
            />

            <ProjectFormItem
              id="areaID"
              title="Area"
              inputElement={
                <AreaDropdown
                  selectedOptions={location?.areaID}
                  handleChange={opts => singleSelectChange(opts, 'areaID')}
                  isMulti={false}
                />
              }
            />

            <ProjectFormItem
              id="locationX"
              title="Location X"
              body={location && location.rack}
              placeholder="Please enter Location X for this location"
              valueChange={e => handleInputChange(e, 'locationX')}
            />
            <ProjectFormItem
              id="locationY"
              title="Location Y"
              body={location && location.shelf}
              placeholder="Please enter Location Y for this location"
              valueChange={e => handleInputChange(e, 'locationY')}
            />
            <ProjectFormItem
              id="locationZ"
              title="Location Z"
              body={location && location.location}
              placeholder="Please enter Location Z for this location"
              valueChange={e => handleInputChange(e, 'locationZ')}
            />
            <ProjectFormItem
              id="barcode"
              title="Barcode"
              body={location?.barcode}
              placeholder="Please enter barcode for this location"
              valueChange={e => handleInputChange(e, 'barcode')}
            />
            <ProjectFormItem
              id="notes"
              title="Notes"
              body={location && location.notes}
              placeholder="Please enter notes for this location"
              valueChange={e => handleInputChange(e, 'notes')}
            />
            {location?.id > 0 ? <ProjectFormItem
              id="status"
              title="Status"
              inputElement={
                <BaseDropdown
                  sources={[
                    { id: 0, display: 'Live' },
                    { id: 1, display: 'Deleted' }
                  ]}
                  selectedOptions={location?.status}
                  handleChange={opts => singleSelectChange(opts, 'status')}
                  isMulti={false}
                />
              }
            /> : null}
            <Row>
              <Col xs={6}>
                <Button
                  variant="primary"
                  disabled={isWaitingSave}
                  onClick={() => saveLocationData()}
                >
                  {!isWaitingSave ? (
                    'Save Location'
                  ) : (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span> ...Loading</span>
                    </>
                  )}
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
