import { configureStore } from '@reduxjs/toolkit';
import projectReducer from './slices/projectSlice';
import areaReducer from './slices/areaSlice';
import authReducer from './slices/authSlice';
import siteReducer from 'slices/siteSlice';
import binReducer from 'slices/binSlice';
const store = configureStore({
  reducer: {
    project: projectReducer,
    user: authReducer,
    area: areaReducer,
    site: siteReducer,
    bin: binReducer
  }
});

export default store;
