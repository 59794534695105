import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';
import ErrorLayout from './ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';
import AppContext from 'context/Context';
import ProjectDashboard from 'components/pages/npd/projects/ProjectDashboard';
import ProjectManager from 'components/pages/npd/projects/ProjectManager';
import AuthError from 'components/errors/AuthError';
import ProtectedRoute from 'components/authenticated-routes/ProtectedRoute';
import Error404 from 'components/errors/Error404';
import SettingsPanel from 'components/settings-panel/SettingsPanel';
// import SettingsToggle from 'components/settings-panel/SettingsToggle';
import NCDashboard from 'components/pages/new-critical/dashboard/NCDashboard';
import Home from 'components/pages/Home';
import AreaList from 'components/pages/stock-location/areas/AreaList';
import AreaManager from 'components/pages/stock-location/areas/AreaManager';
import SiteList from 'components/pages/stock-location/sites/SiteList';
import SiteManager from 'components/pages/stock-location/sites/SiteManager';
import LocationList from 'components/pages/stock-location/locations/LocationList';
import LocationManager from 'components/pages/stock-location/locations/LocationManager';
import BinList from 'components/pages/stock-location/bins/BinList';
import BinManager from 'components/pages/stock-location/bins/BinManager';
import StockTransactionList from 'components/pages/stock-location/stock-transactions/StockTransactionList';
import StockList from 'components/pages/stock-location/stocks/StockList';
import StockSearch from 'components/pages/stock-location/stocks/StockSearch';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        {/* {Auth errors} */}
        <Route element={<ErrorLayout />}>
          <Route path="errors/authError" element={<AuthError />} />
        </Route>

        {/* {MainLayout}  */}
        <Route path="/" element={<ProtectedRoute />}>
        {/* <Route> */}
          <Route element={<MainLayout />}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="npd/dashboard" element={<ProjectDashboard />} />
            <Route exact path="npd/project-manager" element={<ProjectManager />} />
            <Route exact path="new-critical/nc-dashboard" element={<NCDashboard />} />
            <Route exact path="stock-location/area-management/areas" element={<AreaList />} />
            <Route exact path="stock-location/area-management/area-manager" element={<AreaManager />} />
            <Route exact path="stock-location/site-management/sites" element={<SiteList />} />
            <Route exact path="stock-location/site-management/site-manager" element={<SiteManager />} />
            <Route exact path="stock-location/bin-management/bins" element={<BinList />} />
            <Route exact path="stock-location/bin-management/bin-manager" element={<BinManager />} />
            <Route exact path="stock-location/location-management/locations" element={<LocationList />} />
            <Route exact path="stock-location/location-management/location-manager" element={<LocationManager />} />
            <Route exact path="stock-location/stock-management/stock-transaction" element={<StockTransactionList />} />
            <Route exact path="stock-location/stock-management/stocks" element={<StockList />} />
            <Route exact path="stock-location/stock-management/stock-search" element={<StockSearch />} />
          </Route>
        </Route>

        {/* {Fallback <Navigate to="/errors/404" />} */}
        <Route path="*" element={<Error404 />} />
      </Routes>

      {/* {Customisation menu} */}
      {/* <SettingsToggle /> */}
      <SettingsPanel />
      {/* {toast container for whole app} */}
      <ToastContainer
        toastClassName='text-white'
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_RIGHT}
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
      />
    </>
  );
};

export default Layout;
