import { createSlice } from '@reduxjs/toolkit';
import { getItemFromStore } from 'helpers/utils';

const { userId, isAuthenticated, name, email } = getItemFromStore('user', {}, sessionStorage);
const initialState = {
    userId: userId,
    userName: name,
    userEmail: email,
    isAuthenticated: isAuthenticated
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        applyUser: (state, action) => {
            if (action.payload) {
                state.userId = action.payload;
            }
        },
        applyUserName: (state, action) => {
            if (action.payload) {
                state.userName = action.payload;
            }
        },
        applyToken: (state, action) => {
            state.token = action.payload;
            state.isAuthenticated = true;
        },
        applyIsAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { applyUser, applyUserName, applyToken, applyIsAuthenticated } = authSlice.actions;

export default authSlice.reducer;