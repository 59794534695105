import React, { useState  } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { searchProducts } from 'services/stock-location/stockService';
import { StockTableRQ } from 'components/pages/stock-location/models/StockTableRQ';

const SlsProductDropdown = ({ selectedOptions, handleChange, isMulti = true }) => {
  const [products, setProducts] = useState([]);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#ffffff' : '#ffffff', 
      color: state.isSelected ? '#000000' : '#000000', 
    }),
  };
   const handleInputChange = (newValue) => {
     if (newValue.length >= 3) {
       searchProducts(new StockTableRQ(1,100, newValue)).then(res => {
         setProducts(res.items.map(x => {
           let lableText = x.productCode;
           return {
             ...x, value: x.id, label: lableText
           }
         }));
       })
         .catch(err => {
           console.log('Error in user fetch', err);
         });
     }
   };

  const handleSelectChange = (option) => {
    handleChange(option);
  }
  
  return (
    <Select
    styles={customStyles}
    classNamePrefix="my-select"
      isMulti={isMulti}
      name="products"
      options={products}
      onInputChange={handleInputChange}
      value={selectedOptions}
      onChange={options => handleSelectChange(options)}
      placeholder="Select a product from the list..."
    />
  );
};

SlsProductDropdown.propTypes = {
  selectedOptions: PropTypes.oneOfType([PropTypes.number, PropTypes.array, PropTypes.object]),
  handleChange: PropTypes.func,
  isMulti: PropTypes.bool
};

export default SlsProductDropdown;
