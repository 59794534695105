import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { applyBin } from "../../../../slices/binSlice";
import { newBin } from "helpers/stockLocationHelpers";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import PageHeader from 'components/common/PageHeader';
import ProjectFormItem from "components/partials/projects/ProjectFormItem";
import { toast } from "react-toastify";
import { getBinById, postBin, patchBin, disableBin, enableBin } from "services/stock-location/binServices";
import BasicTemplate from "components/Toast/BasicTemplate";
import BaseDropdown from 'components/Dropdowns/BaseDropdown';
import LocationDropdown from 'components/Dropdowns/SlsLocationDropdown'


export default function BinManager() {
    const [params] = useSearchParams();
    const [isWaitingSave, setIsWaitingSave] = useState(false);
    const dispatch = useDispatch();
    const bin = useSelector(state => state.bin.selectedBin);
    const binType = Object.freeze({
        Pallet: 0,
        Stillage: 1,
        Rack: 2,
    });

    useEffect(() => {
        fetchData();
        return () => {
            dispatch(applyBin(null));
        };
    }, []);

    const fetchData = () => {
        if (params.has('id')) {
            const id = params.get('id');
            getBinById(id)
                .then(res => {
                    if (res?.data && res?.data.id > 0) {
                        dispatch(applyBin(handleMapBin(res?.data)));
                        return;
                    }
                });
            return;
        }

        dispatch(applyBin(handleMapBin(newBin)));
        return;
    }

    const getBinTypeText = (type) => {
        return Object.keys(binType).find(key => binType[key] === type);
    }
    const handleMapBin = (data) => {
        return {
            ...data,
            type: binType[data.type]
        }
    }
    const handleInputChange = (e, name) => {
        const { target: { value } } = e;

        switch (name) {
            case 'barcode':
                dispatch(applyBin({
                    ...bin,
                    barcode: value,
                }));
                break;
            default:
                break;
        }
        return;
    }

    const singleSelectChange = (option, key) => {
        switch (key) {
            case 'type':
                dispatch(
                    applyBin({
                        ...bin,
                        type: option.value
                    })
                );
                break;
            case 'location-barcode':
                console.log(option)
                dispatch(
                    applyBin({
                        ...bin,
                        locationBarcode: option.barcode,
                        locationId: option.id,
                    })
                );
                break;
            default:
                return;
        }
        return;
    };
    const saveBinData = async () => {
        setIsWaitingSave(true);
        if (!checkRequired()) {
            setIsWaitingSave(false);
            return false;
        }

        console.log(bin)
        let result = null;
        if (bin.id <= 0) {
            result = await postBin(bin);
        }
        else {
            result = await patchBin(bin, bin.id);
        }

        const message = result.message ?? "There was a problem saving the bin. Please check your entires and try again.";
        result.success ? toast.success(<BasicTemplate success={result.success} text="Bin successfully saved!" />) :
            toast.error(<BasicTemplate success={result.success} text={message} />);
        window.scrollTo(0, 0);
        setIsWaitingSave(false);
        return;
    }
    const isStatusLive = () => {
        return bin?.status == "Live" || bin?.id == 0;
    }

    const handleDisableBin = async () => {
        setIsWaitingSave(true);
        let result = null;
        console.log(bin);

        if (isStatusLive()) {
            result = await disableBin(bin.id);
        }
        else {
            result = await enableBin(bin.id);
        }

        if (result.success == true) {
            fetchData();
        }

        const message = result.message ?? "There was a problem saving the bin. Please check your entires and try again.";
        result.success ? toast.success(<BasicTemplate success={result.success} text="Bin successfully updated!" />) :
            toast.error(<BasicTemplate success={result.success} text={message} />);
        window.scrollTo(0, 0);
        setIsWaitingSave(false);
        return;
    }

    const checkRequired = () => {
        let valid = true;
        if (bin.barcode === "") {
            toast.error(<BasicTemplate text="Please enter a Barcode to continue." />);
            valid = false;
        }
        if (bin.locationBarcode === "") {
            toast.error(<BasicTemplate text="Please select LocationBarcode to continue." />);
            valid = false;
        }
        return valid;
    }

    return (
        <Row>
            <Col xs={12}>
                <PageHeader
                    title={((bin && bin.id > 0) ? "Edit " + bin.barcode : 'Create Bin')}
                    description={`<p>Use this page to manage your bins. Assign actions below the description.</p><p>Leave an input blank to ignore the field.</p>`}
                    className="mb-3"
                ></PageHeader>
                <Card>
                    <Card.Body>
                        <ProjectFormItem
                            id="location-barcode"
                            title="Location Barcode"
                            inputElement={
                                <LocationDropdown
                                    selectedOptions={bin?.locationId}
                                    handleChange={opts => singleSelectChange(opts, 'location-barcode')}
                                />
                            }
                        />
                        {/* <LocationSearchDropdown /> */}
                        <ProjectFormItem
                            readOnly={!isStatusLive()}
                            id="barcode"
                            title="Barcode"
                            body={bin && bin.barcode}
                            placeholder="Please enter barcode for this bin"
                            valueChange={(e) => handleInputChange(e, "barcode")}
                        />
                        {/* <ProjectFormItem
                            id="locationBarcode"
                            readOnly={!isStatusLive()}
                            title="Location Barcode"
                            body={bin && bin.locationBarcode}
                            placeholder="Please enter location barcode for this bin"
                            valueChange={(e) => handleInputChange(e, "locationBarcode")}
                        /> */}
                        <ProjectFormItem
                            id="type"
                            title="Type"
                            inputElement={
                                <BaseDropdown
                                    sources={Object.keys(binType).map(key => ({
                                        id: binType[key],
                                        display: getBinTypeText(binType[key])
                                    }))}
                                    selectedOptions={bin?.type}
                                    handleChange={opts => singleSelectChange(opts, 'type')}
                                    isMulti={false}
                                />
                            }
                        />
                        <Row>
                            <Col xs={6}>
                                <Button
                                    variant="primary"
                                    disabled={isWaitingSave}
                                    onClick={() => saveBinData()}
                                >{!isWaitingSave ? 'Save Bin' :
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /><span>{' '}...Loading</span>
                                    </>
                                    }
                                </Button>
                            </Col>
                            <Col xs={6}>
                                {bin?.id > 0 ? <Button
                                    style={{
                                        float: 'right',
                                    }}
                                    variant={!isStatusLive() ? 'success' : 'danger'}
                                    disabled={isWaitingSave}
                                    onClick={() => handleDisableBin()}
                                >{isStatusLive() ? 'Disable' : 'Enable'}
                                </Button> : null}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}