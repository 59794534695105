import React from 'react';
import PropTypes from 'prop-types';
import PartialTable from "components/partials/stock-location/PartialTable";

const StockTransactionColumns = [
    {
        accessor: 'no',
        Header: 'No.'
    },
    {
        accessor: 'productCode',
        Header: 'Product code'
    },
    {
        accessor: 'productDescription',
        Header: 'Product description'
    },
    {
        accessor: 'mmPerBarUnitsPerPack',
        Header: 'Metres per length/units per pack'
    },
    {
        accessor: 'transactionQty',
        Header: 'Lengths / Units'
    },
    {
        accessor: 'uom',
        Header: 'UOM'
    },
    {
        accessor: 'from',
        Header: 'From',
        Cell: row => <div><a
            data-tooltip-id="my-tooltip"
            data-tooltip-content={row.row.values.fromLocation}
        >
            {row.value}
        </a></div>
    },
    {
        accessor: 'fromLocation',
        Header: 'From Location',
        isVisible: false
    },
    {
        accessor: 'to',
        Header: 'To',
        Cell: row => <div><a
            data-tooltip-id="my-tooltip"
            data-tooltip-content={row.row.values.toLocation}
        >
            {row.value}
        </a></div>
    },
    {
        accessor: 'toLocation',
        Header: 'To Location',
        isVisible: false
    },
    {
        accessor: 'overallQtyOnHand',
        Header: 'Overall QTY on hand',
    },
    {
        accessor: 'transactionType',
        Header: 'Transaction type',
    },
    {
        accessor: 'transactionRef',
        Header: 'Transaction Ref.',
    },
    {
        accessor: 'date',
        Header: 'Date / Time of transaction',
        type: 'date'
    },
    {
        accessor: 'actionBy',
        Header: 'Actioned by',
    }
];

const StockTransactionTable = ({ transactions, paging, total, nextPage, previousPage, gotoPage, onPageSizeChange, loading = false }) => {
    return (
        <PartialTable
            data={transactions}
            columns={StockTransactionColumns}
            paging={paging}
            total={total}
            nextPage={nextPage}
            previousPage={previousPage}
            gotoPage={gotoPage}
            onPageSizeChange={onPageSizeChange}
            loading={loading}
        // view = {"/stock-location/area-management/area-manager?id="}
        />
    )
}

StockTransactionTable.propTypes = {
    transactions: PropTypes.arrayOf(PropTypes.object),
    paging: PropTypes.object,
    total: PropTypes.number,
    nextPage: PropTypes.func,
    previousPage: PropTypes.func,
    gotoPage: PropTypes.func,
    onPageSizeChange: PropTypes.func,
    loading: PropTypes.bool
}

export { StockTransactionTable, StockTransactionColumns };