import axios from 'axios';
import { URL } from '../../helpers/urlHelper';
import { BaseTableRS } from 'components/pages/stock-location/models/BaseTable';
import { objectToQueryString } from '../../helpers/utils'
import { ApiResponse } from 'helpers/stockLocationHelpers'
axios.defaults.withCredentials = true;

const searchSites = async (request) => {
    const res = await axios({
        method: 'get',
        url: `${URL}/sites/search?${objectToQueryString(request)}`,
    })
        .catch((error) => {
            console.log('AXIOS ERROR', error)
            return new BaseTableRS([], 0);
        });

    if (res.status !== 200)
        return new BaseTableRS([], 0);

    return new BaseTableRS(res.data?.data?.items, res.data?.data?.total);
}

const getSiteById = async (id) => {
    const result = new ApiResponse();
    const res = await axios({
        method: 'get',
        url: `${URL}/sites/${id}`,
    })
        .catch((error) => {
            console.log('AXIOS ERROR', error)
            return result;
        });

    if (res.status !== 200) {
        return result;
    }
    result.success = res.data.success;
    result.data = res.data?.data
    result.message = res.data?.message;
    return result;
}


const getAllSites = async () => {
    const res = await axios({
        method: 'get',
        url: `${URL}/sites`,
    })
        .catch((error) => {
            console.log('AXIOS ERROR', error)
            return new BaseTableRS([], 0);
        });

    if (res.status !== 200)
        return new BaseTableRS([], 0);
    return new BaseTableRS(res.data?.data?.items, res.data?.data?.total);
}
const postSite = async (site) => {
    const result = new ApiResponse();
    if (!site) return result;
    const res = await axios({
        method: 'post',
        url: `${URL}/sites`,
        data: { ...site },
        config: {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            }
        },
    })
        .catch((error) => {
            console.log('ERROR: ', { ...error });
            return result;
        });
    if (res.status !== 200) {
        return result;
    }

    result.success = res.data.success;
    result.data = res.data?.data
    result.message = res.data?.message;
    return result;
}

const patchSite = async (site, id) => {
    const result = new ApiResponse();
    if (!site || id <= 0) return result;
    const res = await axios({
        method: 'patch',
        url: `${URL}/sites/${id}`,
        data: { ...site },
        config: {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            }
        },
    })
        .catch((error) => {
            console.log('ERROR: ', { ...error });
            return result;
        });

    if (res.status !== 200) {
        return result;
    }
    result.success = res.data.success;
    result.data = res.data?.data
    result.message = res.data?.message;
    return result;
}

const disableSite = async (id) => {
    const result = new ApiResponse();
    console.log(id);
    if (id <= 0) return result;
    const res = await axios({
        method: 'delete',
        url: `${URL}/sites/${id}`,
    })
        .catch((error) => {
            console.log('ERROR: ', { ...error });
            return result;
        });

    if (res.status !== 200) {
        return result;
    }
    result.success = res.data.success;
    result.data = res.data?.data
    result.message = res.data?.message;
    return result;
}
const enableSite = async (id) => {
    const result = new ApiResponse();
    if (id <= 0) return result;
    const res = await axios({
        method: 'patch',
        url: `${URL}/sites/${id}/enable-status`,
    })
        .catch((error) => {
            console.log('ERROR: ', { ...error });
            return result;
        });

    if (res.status !== 200) {
        return result;
    }
    result.success = res.data.success;
    result.data = res.data?.data
    result.message = res.data?.message;
    return result;
}
export { searchSites , getAllSites, postSite, getSiteById, patchSite, enableSite, disableSite };