import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

const PartialTable = ({ data, columns, paging, total, nextPage, previousPage, gotoPage, view, tableRef, loading = false }) => {
    const [items, setItems] = useState([]);
    useEffect(() => {
        setItems(
            data && data.length > 0 ? data.map((p, i) => {
                let result = {};
                columns.map((c) => {
                    result[c.accessor] = p[c.accessor]
                    if (c.type === 'date') {
                        result[c.accessor] = dayjs(p[c.accessor]).format('DD/MM/YYYY hh:mm')
                    }
                });
                result['no'] = i + 1;
                result['action'] = <Link to={`${view}${p.id}`}>Edit</Link>;
                return result;
            }) : []
        );
    }, [data]);

    return (
        <div>
            <AdvanceTableWrapper
                columns={columns}
                data={items}
                sortable
                pagination
                perPage={paging?.pageSize}
                selectionColumnWidth="20%"
            >
                <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 align-middle text-center" //text-nowrap
                    rowClassName="align-middle white-space-nowrap text-center"
                    tableProps={{
                        bordered: true,
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden',
                        responsive: true
                    }}
                    tableRef={tableRef}
                    loading={loading}
                />
                <Tooltip id="my-tooltip" />
                <div className="mt-3">
                    <AdvanceTablePagination
                        canPreviousPage={paging?.pageNum > 1}
                        canNextPage={paging?.pageNum < Math.ceil(total / paging?.pageSize)}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        pageCount={Math.ceil(total / paging?.pageSize)}
                        pageIndex={paging?.pageNum - 1}
                        gotoPage={gotoPage}
                    />
                </div>
            </AdvanceTableWrapper>
        </div >
    )
}

PartialTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.arrayOf(PropTypes.object),
    paging: PropTypes.object,
    total: PropTypes.number,
    nextPage: PropTypes.func,
    previousPage: PropTypes.func,
    gotoPage: PropTypes.func,
    view: PropTypes.string,
    tableRef: PropTypes.object,
    loading: PropTypes.bool,
}

export default PartialTable;