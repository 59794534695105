import React, { useState, useEffect } from "react";
import { Card, Row } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import SitesTable from "components/partials/stock-location/sites/SiteTable";
import { searchSites } from "services/stock-location/siteServices";
import { BaseTableRQ } from "../models/BaseTable"
import { useDispatch } from "react-redux";
import { applySite } from "../../../../slices/siteSlice";

export default function SiteList() {
    const [sites, setSites] = useState([]);
    const [total, setTotal] = useState(0);
    const [paging, setPaging] = useState(new BaseTableRQ(1,100));

    const dispatch = useDispatch();
    dispatch(applySite(null));
    
    useEffect(() => {
        loadSites(paging);
    }, [])

    const loadSites = (pagingRq) => {
        searchSites(pagingRq).then(res => {
            if (res) 
            {
                setSites(res.items);
                setTotal(res.total);
            }
        })
            .catch(err => {
                console.log(err);
            });
    }

    const gotoPage = (index) => {
        index +=1;
        if(index <= 0) return;
        paging.pageNum = index;
        setPaging(paging);
        loadSites(paging);
    }

    const nextPage = () => {
        const page = paging.pageNum  + 1;
        if(page > Math.ceil(total/paging.pageSize)) return;
        paging.pageNum = page;
        setPaging(paging);
        loadSites(paging);
    }

    const previousPage = () => {
        const page = paging.pageNum -1;
        if(page <= 0) return;
        paging.pageNum = page;
        setPaging(paging);
        loadSites(paging);
    }

    return (
        <>
            <PageHeader
                title="Site List"
                description={`Manage and view all available Sites from this page.`}
                className="mb-3"
            ></PageHeader>
            <Card>
                <Card.Body className="overflow-hidden p-lg-6">
                    <Row className="align-items-center justify-content-between">
                        <SitesTable data={sites} paging={paging} total={total}
                        nextPage = {nextPage}
                        previousPage = {previousPage}
                        gotoPage = {gotoPage}/>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}